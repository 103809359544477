import s from "./style.module.sass";
import clsx from "clsx";
import { NeuralQA } from "@features/NeuralQAModal/model/typedef";

type CourseQuestionAndAnswerProps = {
	content: NeuralQA[] | [];
	type: "preview" | "full";
};

const convertAnswerToString = (text: string) => {
	return text.replace(/(\\n\\n)/gm, "\n\n");
};

const CourseQuestionAndAnswer = ({ content, type }: CourseQuestionAndAnswerProps) => {
	const wrapperClasses = clsx({
		[s.previewWrapper]: type === "preview",
		[s.fullWrapper]: type === "full",
	});
	const imageClasses = clsx({
		[s.previewImage]: type === "preview",
		[s.fullImage]: type === "full",
	});

	return (
		<>
			{content?.map((item, i) => {
				return (
					<div key={i} className={wrapperClasses}>
						{/* Внутри карточки курса отображается только item с responder === expert, backend отказался изменять ключ https://github.com/spiks/selfschool-backend/issues/1179*/}
						<h2 className={s.title}>{"Отвечает Нейросеть"}</h2>
						<h3 className={clsx(s.subtitle, s.question)}>Вопрос</h3>
						<p className={s.text}>{item.question}</p>
						<h3 className={clsx(s.subtitle, s.answer)}>Ответ</h3>
						<p className={s.text}>{convertAnswerToString(item.answer)}</p>
						<div
							className={imageClasses}
							style={{
								background: `top right / contain no-repeat url("${
									item?.imageUrl ?? "/images/neural-qa.svg"
								}")`,
							}}
						/>
					</div>
				);
			})}
		</>
	);
};

export default CourseQuestionAndAnswer;
