import { useRouter } from "next/router";
import { useEvent } from "effector-react/scope";
import { $purchasedCourses, getPurchasedCourses, getPurchasedCoursesFx } from "../model/public";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { PurchasedCoursesList } from "@services/purchasedCourses/typedef";
import { CourseListingPagination } from "@shared/UI/CoursesListing";
import { $page, updatePurchasedCoursesPage } from "../model/private";
import { $isAuth } from "src/process/authProcess/model/public";

type UsePurchasedCourseListingResult = {
	onCourseCardClick: (url: string) => void;
	hasPurchasedCourses: boolean;
	purchasedCourses: PurchasedCoursesList | null;
	isLoading: boolean;
	paginationOptions: CourseListingPagination;
};

export const usePurchasedCourseListing = (): UsePurchasedCourseListingResult => {
	const isAuth = useStore($isAuth);
	const purchasedCourses = useStore($purchasedCourses);
	const isPurchasedCoursesLoading = useStore(getPurchasedCoursesFx.pending);
	const getPurchasedCoursesEvent = useEvent(getPurchasedCourses);
	const page = useStore($page);
	const updatePurchasedCoursesPageEvent = useEvent(updatePurchasedCoursesPage);

	const hasPurchasedCourses = purchasedCourses !== null && purchasedCourses?.totalCount > 0;

	const onShowMoreButtonClick = () => {
		if (purchasedCourses && page < purchasedCourses?.totalPage) {
			updatePurchasedCoursesPageEvent(page + 1);
		}
	};

	const paginationOptions = {
		currentPage: purchasedCourses?.currentPage ?? 1,
		isCoursesLoading: isPurchasedCoursesLoading,
		totalPage: purchasedCourses?.totalPage ?? 0,
		onShowMoreButtonClick,
	};

	const { push } = useRouter();

	const onCourseCardClick = (url: string) => {
		push(`/course/${url}/watch`);
	};

	useEffect(() => {
		if (isAuth) getPurchasedCoursesEvent();
	}, [isAuth]);

	return {
		onCourseCardClick,
		hasPurchasedCourses,
		isLoading: isPurchasedCoursesLoading,
		purchasedCourses,
		paginationOptions,
	};
};
