import { createDomain } from "effector";

import { NeuralQA } from "./typedef";

export const neuralQAContent = createDomain("neuralQA");

export const resetNeuralQaContent = neuralQAContent.event();

export const $neuralQAContent = neuralQAContent.store<NeuralQA[]>([]);

export const $isModalOpen = neuralQAContent.store<boolean>(false);
