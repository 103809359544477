import { FC } from "react";
import Link from "next/link";
import { ROUTES } from "../../../../../../constants/ROUTES";
import { useStore } from "effector-react";
import { $profileInfo } from "@components/views/SettingsView/PersonalSettingsView/model/private";

const DescriptionForPurchasedCourses: FC = () => {
	const profile = useStore($profileInfo);

	return (
		<p>
			Добрый день, {profile?.firstName}! Сейчас подходящий момент, чтобы изучить что-то новое или закрепить
			изученное на практике!
			<br />
			Все купленные курсы доступны в разделе <Link href={ROUTES.MY_EDUCATION_COURSES}>Мое обучение</Link>
		</p>
	);
};

export default DescriptionForPurchasedCourses;
