import s from "./styles.module.sass";
import Button, { ButtonProps } from "@components/UI/Button";
import clsx from "clsx";

type ShowMoreButtonProps = {
	isLoading: boolean;
	onClick: () => void;
	totalPage: number;
	currentPage?: number;
	className?: HTMLDivElement["className"];
} & Partial<ButtonProps>;

const ShowMoreButton = ({
	isLoading,
	totalPage,
	onClick,
	className,
	currentPage,
	...props
}: ShowMoreButtonProps) => {
	if (totalPage <= 1) {
		return null;
	}

	return (
		<div className={clsx(s.buttonWrapper, className)}>
			<Button
				{...props}
				text="Показать еще"
				upperCase
				isLoading={isLoading}
				onClick={onClick}
				disabled={currentPage === totalPage}
			/>
		</div>
	);
};

export default ShowMoreButton;
