import { sample } from "effector";

import { $isModalOpen, $neuralQAContent, resetNeuralQaContent } from "./private";
import { toggleNeuralModalOpenStatus, updateNeuralQAEvent } from "./public";

$neuralQAContent.reset(resetNeuralQaContent);

sample({
	clock: updateNeuralQAEvent,
	target: $neuralQAContent,
});

sample({
	clock: toggleNeuralModalOpenStatus,
	target: $isModalOpen,
});
