import { FC } from "react";
import CourseCover from "../CourseCover";
import LinearProgress from "@components/UI/LinearProgress";
import s from "./styles.module.sass";
import clsx from "clsx";
import { truncateString } from "@shared/functions/truncateString";
import { PurchasedCourse } from "@services/purchasedCourses/typedef";

type ProgressCourseCard = {
	info: PurchasedCourse;
	isFill?: boolean;
	onClick: () => void;
};

const ProgressCourseCard: FC<ProgressCourseCard> = ({ info, isFill, onClick }) => {
	const { lectures: progress } = info;

	const lecturesAmount = progress.length;

	const completedLecturesAmount = progress.filter((lecture) => {
		return lecture.isComplete === true;
	}).length;

	const lastLectureTitle = progress.at(-1)?.title;

	return (
		<button
			className={clsx(s.courseCard, {
				[s.isFill]: isFill,
			})}
			onClick={onClick}
		>
			<CourseCover id={info.id} noPromoPlay cover={info.coverUrl} promo={info.teaserUrl} variant="vertical" />
			<div className={s.courseInfo}>
				<div className={s.infoHeader}>
					<p className={s.title}>{truncateString(info.title, 30)}</p>
					<h3 className={s.subtitle}>{lastLectureTitle}</h3>
				</div>

				<div className={s.infoFooter}>
					<p className={s.progress}>
						Пройдено {((completedLecturesAmount / lecturesAmount) * 100).toFixed(0)}%
					</p>
					<LinearProgress progress={Math.floor((completedLecturesAmount / lecturesAmount) * 100)} />
				</div>
			</div>
		</button>
	);
};

export default ProgressCourseCard;
