import DynamicModal from "@components/UI/DynamicModal";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { $isModalOpen, $neuralQAContent, resetNeuralQaContent } from "../../model/private";
import { toggleNeuralModalOpenStatus } from "../../model/public";
import CourseQuestionAndAnswer from "@components/UI/CourseQuestionAndAnswer";
import "../../model/init";

const NeuralQAModal = () => {
	const isOpen = useStore($isModalOpen);
	const toggleModalOpenStatusEvent = useEvent(toggleNeuralModalOpenStatus);
	const neuralContent = useStore($neuralQAContent);
	const resetNeuralQaContentEvent = useEvent(resetNeuralQaContent);

	const closeModal = () => {
		toggleModalOpenStatusEvent(false);
		resetNeuralQaContentEvent();
	};

	return (
		<DynamicModal isOpen={isOpen} onClose={closeModal}>
			{neuralContent !== null && neuralContent[0]?.question && (
				<CourseQuestionAndAnswer content={[neuralContent[0]]} type="preview" />
			)}
		</DynamicModal>
	);
};

export default NeuralQAModal;
