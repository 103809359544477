import clsx from "clsx";
import Image from "next/image";
import React, { useState } from "react";
import s from "./styles.module.sass";
import DynamicModal from "@components/UI/DynamicModal";
import dynamic from "next/dynamic";
import defaultCover from "../../../../public/images/photoLoaderBg.png";
import { useWebAnalyticsContext } from "@shared/analytics/webAnalytics.contexts";

const DynamicVideoPlayer = dynamic(
	() => {
		return import("@components/UI/Media/VideoPlayer");
	},
	{
		ssr: false,
		loading: () => {
			return <p>Загрузка...</p>;
		},
	}
);

type CourseCoverProps = {
	cover?: string | null;
	promo: string;
	id: number;
	courseTitle?: string;
	variant?: "horizontal" | "vertical";
	isFill?: boolean;
	noPromoPlay?: boolean;
};

const CourseCover = ({ cover, promo, courseTitle, variant, isFill, noPromoPlay, id }: CourseCoverProps) => {
	const [isPromoOpened, setIsPromoOpened] = useState(false);
	const { promoView, promoClick } = useWebAnalyticsContext();
	const localCover = !!cover ? cover : defaultCover;

	const handleModalClose = () => {
		setIsPromoOpened(false);
	};

	const onPromoClick = () => {
		if (noPromoPlay) {
			return;
		}
		setIsPromoOpened(true);
		if (courseTitle) {
			const promo = {
				name: courseTitle,
				id: id.toString(),
				creative: courseTitle,
				position: "1",
			};
			promoClick(promo);
			promoView(promo);
		}
	};
	return (
		<div
			className={clsx(s.coverWrapper, {
				[s.vertical]: variant === "vertical",
				[s.fill]: isFill,
			})}
		>
			<Image
				loader={
					typeof localCover === "string"
						? () => {
								return localCover;
						  }
						: undefined
				}
				unoptimized
				priority
				src={localCover}
				layout="fill"
				objectFit="cover"
				alt="Обложка курса"
			/>
			{promo && (
				<div className={s.playCover}>
					<button className={s.playButton} onClick={onPromoClick} />
				</div>
			)}
			<DynamicModal className={s.modal} isOpen={isPromoOpened} onClose={handleModalClose}>
				<h3 className={s.modalTitle}>Рекламное видео</h3>
				<p className={s.courseTitle}>{courseTitle}</p>
				{isPromoOpened && <DynamicVideoPlayer videoWrapperClassName={s.playerWrapper} videoUrl={promo} />}
			</DynamicModal>
		</div>
	);
};

export default CourseCover;
