import style from "./style.module.sass";
import ShowMoreButton from "../ShowMoreButton";
import clsx from "clsx";

export type CourseListingPagination = {
	isCoursesLoading: boolean;
	onShowMoreButtonClick: () => void;
	totalPage: number;
	currentPage: number;
};

type CoursesListingProps<T> = {
	wrapperClassName?: HTMLDivElement["className"];
	listClassName?: HTMLDivElement["className"];
	courseComponentRenderer: (course: T, key: number) => JSX.Element;
	cardSize?: "medium" | "big";
	courses: Array<T>;
	pagination?: CourseListingPagination;
	title?: string;
	descriptionComponent?: JSX.Element;
};
const CoursesListing = <T,>({
	courses,
	pagination,
	title,
	descriptionComponent,
	courseComponentRenderer,
	cardSize = "medium",
	wrapperClassName,
	listClassName,
}: CoursesListingProps<T>) => {
	const listStyles = clsx(style.list, listClassName, {
		[style.big]: cardSize === "big",
		[style.medium]: cardSize === "medium",
	});
	return (
		<div className={clsx(style.wrapper, wrapperClassName)}>
			{title && <h2 className={style.title}>{title}</h2>}
			{descriptionComponent && descriptionComponent}
			<div className={listStyles}>
				{courses?.map((course, key) => {
					return courseComponentRenderer(course, key);
				})}
			</div>
			{pagination && (
				<ShowMoreButton
					currentPage={pagination.currentPage}
					isLoading={pagination.isCoursesLoading}
					onClick={pagination.onShowMoreButtonClick}
					totalPage={pagination.totalPage}
				/>
			)}
		</div>
	);
};

export default CoursesListing;
