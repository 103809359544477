import s from "./styles.module.sass";
import { FC } from "react";
import CoursesListing from "../../../shared/UI/CoursesListing";
import ProgressCourseCard from "../../../shared/UI/ProgressCourseCard";
import DescriptionForPurchasedCourses from "@components/views/MainView/view/parts/DescriptionForPurchasedCourses";
import { usePurchasedCourseListing } from "../hooks/usePurchasedCourseListing";
import { PurchasedCourse } from "@services/purchasedCourses/typedef";

const PurchasedCourseListing: FC = () => {
	const { onCourseCardClick, purchasedCourses, hasPurchasedCourses, paginationOptions } =
		usePurchasedCourseListing();

	return (
		<>
			{hasPurchasedCourses ? (
				<section className={s.userCourses}>
					<div className={`${s.coursesWrapper} mobileHorizontallyScrollable`}>
						<CoursesListing<PurchasedCourse>
							title="Ваши курсы"
							courses={purchasedCourses?.items ?? []}
							pagination={paginationOptions}
							descriptionComponent={<DescriptionForPurchasedCourses />}
							cardSize={"big"}
							courseComponentRenderer={(course) => {
								return (
									<ProgressCourseCard
										key={course.id}
										info={course}
										isFill
										onClick={() => {
											onCourseCardClick(course.url.toString());
										}}
									/>
								);
							}}
						/>
					</div>
				</section>
			) : null}
		</>
	);
};

export default PurchasedCourseListing;
